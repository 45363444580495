<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <img
          src="@/assets/images/icons8-discussion-forum-96.png"
          class="h-20 ml-2 inline-block"
        />
        ایجاد صف جدید
      </h2>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Channel" />
        {{ channel.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ channel.cid }}</div>
    </template>
    <template #default>
      <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md">
        <form @submit.prevent="submit">
          <div class="grid sm:grid-cols-2 xl:grid-cols-3 gap-2">
            <MnrText placeholder="یک نام وارد کنید" v-model="name" title="نام صف" />
          </div>
          <!-- <hr class="border-blueGray-200 my-5" />
          <div class="grid grid-cols-2 sm:grid-cols-4 2xl:grid-cols-8 gap-2 mt-4">
            <div
              v-for="(time, index) in times"
              :key="index"
              class="w-full bg-blueGray-50 border border-blueGray-200 p-2 rounded"
            >
              <Button
                class="rounded-full h-5 w-5 mb-2 inline-block p-0"
                color="red"
                @click="times.splice(index, 1)"
              >
                ×
              </Button>
              <MnrText v-model="time.value" type="time" />
            </div>
          </div>
          <div class="flex flex-wrap gap-2">
            <Button
              class="mt-3 inline-flex text-lg"
              color="teal"
              @click="times.push({ value: '' })"
            >
              اضافه کردن
            </Button>
          </div> -->
          <div class="sm:w-60 w-full mt-3 mx-auto">
            <Button type="submit" class="mt-3 text-lg" color="sky">ثبت</Button>
          </div>
        </form>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";
import Button from "@/components/Button.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import _ from "lodash";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
    MnrText,
  },
  data() {
    return {
      firstLoading: true,
      channel: {},
      queue: {},
      name: "",
      times: [{ value: "" }, { value: "" }, { value: "" }, { value: "" }],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      $this.$axios
        .get("api/owner-channels/" + $this.$route.params.channelId, {
          params: {
            id: $this.$store.state.user.id,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.channel = response.data.channel;
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.firstLoading = false;
        });
    },
    submit() {
      let $this = this;
      $this.posts = [];
      $this.$axios
        .post(`api/owner-channels/${$this.$route.params.channelId}/queue`, {
          id: $this.$store.state.user.id,
          name: $this.name,
          // times: $this.times,
        })
        .then(function (response) {
          // console.log(response);
          $this.$router.back();
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.firstLoading = false;
        });
    },
  },
};
</script>
